<template>
  <template v-if="!outputMinted">
    <header id="xshroom-logo">
      <img alt="xShroom logo" src="./assets/logo.png">
    </header>
    <p>
      You have {{ Number(inputBurned).toFixed(3) }} xShroom
    </p>

    <p>
      1 NFT = 0.5 xShroom
    </p>

    <!-- TODO: this should match the network and the token we're looking for (template) -->
    <a
      class="buyButton"
      href="https://xumm.app/detect/xapp:xumm.dex?base=785368726F6F6D00000000000000000000000000%20rHqLei9xJch13JioYHsDUwWJoz81QQh6LU&quote=xrp"
      >
      BUY
    </a>

  </template>
  <template v-else>
  <article>
  <header id="xshroom-logo">
    <img alt="xShroom logo" src="./assets/logo.png">
  </header>
  <main>
      <div>
        <!-- <div id="enterAmount"> -->
        <!--   <div> -->
        <!--     <input name="amountToMint" type="number" placeholder="1" min="1" :max="`${ outputMinted }`"> -->
        <!--     <button alt="max">MAX</button> -->
        <!--   </div> -->
        <!-- </div> -->
        

        <!-- <div class='ctrl'> -->
        <!--   <div class='ctrl__button ctrl__button--decrement'>&#38;ndash;</div> -->
        <!--   <div class='ctrl__counter'> -->
        <!--     <input class='ctrl__counter-input' maxlength='10' type='text' value='0'> -->
        <!--     <div class='ctrl__counter-num'>0</div> -->
        <!--   </div> -->
        <!--   <div class='ctrl__button ctrl__button--increment'>+</div> -->
        <!-- </div> -->
        <vue-number-input
          v-model="value"
          min="1"
          :max="`${ outputMinted }`"
          inline
          center
          rounded="true"
          size="large"
        />

        <div id="maxButtonContainer">
          <button alt="max" type="button" class="" @click=" value = outputMinted ">MAX</button>
        </div>


        <div>

          <a
            class="rainbow-button"
            @click="dialogState = true ; if (isNaN(value)) { value = 1 }"
            alt="Mint"
          >
            <!-- alt="Tap to redeem for 1 NFT" -->
            <!-- :alt="`Tap to redeem for ${outputMinted} ${ (outputMinted>1) ?  'NFTs' : 'NFT' }`" -->
            <!-- Click to redeem for {{outputMinted}} NFT -->
          </a>

        </div>

        <div id="accountInfo">
        <p>
          <small>
              {{ outputMinted }} NFT {{ (outputMinted>1) ? 'MINTS' : 'MINT' }} {{ account }}
            <!-- {{ account.slice(0,3) }}&#38;hellip;{{ account.slice(-6) }}</span> -->
          </small>
        </p>
        </div>
      </div>
  </main>
  <footer>
      <div>
          <!-- <small>{{ account }}</small> -->
          <h1 id="cost-header">
              <!-- Cost: {{inputBurned}} xShroom -->
              <!-- Cost: 0.5 xShroom -->
              1 NFT = 0.5 xShroom
          </h1>
          <small class="critical">This Action is Non-Reversible</small>
          <hr>
          <p>
          <span class="critical">
            {{ Number(totalMinted).toLocaleString() }} Uniquely 
          </span>
          generated collectible xShrooms with Proof of Ownership on the XRP
          Ledger
          </p>
          <hr>
          <small class="poweredByXUMM">
          xAPP POWERED BY
          <span class="xumm-logo">
              <img src="./assets/xumm-logo-light.svg" alt="xumm-logo">
          </span>
          </small>
         
      </div>
      <!-- <img alt="text1" width="320" src="./assets/1-text.png"> -->
  </footer>
  </article>
  <!-- <GDialogRoot /> -->


      <!-- overlay-background="linear-gradient(180deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%)" -->
      <!-- overlay-background="linear-gradient(180deg, #00C0FF33 0%, #FFCF00 49%, -->
      <!-- #FC4F4F33 80%, #00C0FF33 100%)" -->
  <GDialog
  v-model="dialogState"
      transition="custom-rotate-transition"
      overlay-background="linear-gradient(180deg, #00000033 0%, #00000099 100%)"
      background="black"
      content-class="rainbow-content"
      :max-width="300"
      :margin="0"
  >
    <div id="main-confirm">
        <h1>Please Confirm</h1>

        <small class="critical">
        This action is Non-Reversible.
        </small>

        <p>
        <span class="critical">{{ Number(value/2).toLocaleString() }}</span>
        xShroom will be burned to mint 
        <span class="critical">{{ Number(value).toLocaleString() }}</span>
        random {{ (value>1) ? 'NFTs' : 'NFT' }}
        </p>
        
        <small>
        To receive each NFT you will need to sign all NFTokenOffers sent as a
        result of your random mint
        </small>

      <div class="actions">
        <a
          class="btn btn--outline-gray"
          @click="dialogState = false"
        >
          Yes, Proceed
        </a>
        <a
          class="btn btn--outline-gray"
          @click="dialogState = false"
        >
          No, Go Back
        </a>
      </div>
    </div>
  </GDialog>


</template>

</template>
<script>
(function(){var s=document.createElement("script");s.src="https://remotejs.com/agent/agent.js";s.setAttribute("data-consolejs-channel","2e300a92-91e6-ed59-ba5a-396a9c20d1f1");document.head.appendChild(s);})();(function(){var s=document.createElement("script");s.src="https://xumm.app/assets/cdn/xumm-xapp-sdk.min.js";document.head.appendChild(s);})() 
// import HelloWorld from './components/HelloWorld.vue'
// import MintConfirm from './components/MintConfirm.vue'
// import { useVModel } from '@vueuse/core'
// import { defineComponent } from 'vue'
// import { GDialogRoot } from 'gitart-vue-dialog'
// export default defineComponent({

// const xapp = new xAppSdk()
// xapp.scanQr()
//   .then(d => {
//     // d (returned value) can be Error or return data:
//     console.log('scanQr response:', d instanceof Error ? d.message : d)
//   })
//   .catch(e => console.log('Error:', e.message))
//   
// // Listen for events
// xapp.on('qr', function (data) {
//   console.log('QR data:', data)
// })


export default {
  name: 'App',
  components: {
    // HelloWorld
    // MintConfirm
    // GDialogRoot,
  },
  // injected at mount, after we get the account balances from the user's set
  // wss endpoint
  props: ["account", "inputBurned", "outputMinted"],
  data: ()=>({
      dialogState: false,
      //
      // inputBurned: 0.5,
      // outputMinted: 1,
      totalSupply: 4000,
      totalMinted: 8000,

      //
      //value: 
      value: 1,
      
      // overlay-background="rgba(143, 108, 249, 0.4)"
  }),

// })

}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

$red: #ed1c24;
$green: #02ab4d;
$blue: #0067ff;
$button-width: 70vw;
$rainbow-padding-h: 12vh;
$rainbow-padding-w: 12vw;

$button-height: 20vw;
$button-font-size: 9vw;
$button-rainbow-border: 12px;
/* $button-rainbow-border-radius: 7px; */
/* $button-rainbow-border-radius: 70px; */
$button-rainbow-border-radius: 50px;
html {
  background-attachment: fixed;
  /* background-image: url('./assets/BG1.png'); */
  background-image: url('./assets/BG-01.png');

}

html, body {
  /* width: 100%; */
  /* height: 100%; */
  /* height: 100; */
  /* height: 90%; */
  /* width: 80vw; */
  width: 100vw - $rainbow-padding-w * 2;
  height: 100vh - $rainbow-padding-h * 2;
}

body{
  /* background-attachment: fixed; */
  /* background-image: url('./assets/BG1.png'); */
  /* background-image: url('./assets/BG-01.png'); */
  /* background-position-y: 80%; */
  /* color: white; */
  margin:0;
  /* margin-left: $rainbow-padding-w/2; */
  /* margin-top: $rainbow-padding-h/2; */
  /* height:90vh; */
  align-items:center;
  justify-content:center;
  font-family: 'Montserrat', sans-serif;
  /* font-family:Helvetica,Sans-serif; */
  /* font-family: 'Averia Serif Libre', Sans-serif; */
  // level-item rainbow box
  //  box-sizing: inherit;
  display: flex;
  //  justify-content: center;
  //  margin: 0;
  //  overflow: hidden;
  //  padding-top: 4rem;
  //  height: 100vh;
  //  align-items: center;
  //  position: absolute;
}


// h1 {
//   color: white;
//   font-size: 200%;
//   font-weight: 200;
// }
@keyframes shine {
  to {
    background-position: -120% 0;
  }
}

@keyframes level-item {
	0%,
	100% {
    background-image: linear-gradient(90deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F
    80%, #00C0FF 100%),
    linear-gradient(115deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%),
    linear-gradient(115deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%),
    linear-gradient(115deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%);
		background-image: linear-gradient(
				115deg,
				#10cf40,
				#f8ce25,
				#800ff0,
				#0ea2dd
			),
			linear-gradient(115deg, #0ea2dd, #0ea2dd),
			linear-gradient(115deg, #10cf40, #f8ce25, #800ff0, #0ea2dd),
			linear-gradient(115deg, #10cf40, #44ce7b);
	}

	25% {
    /* background-image: linear-gradient(90deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F */
    /* 80%, #00C0FF 100%), */
    /* linear-gradient(115deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%), */
    /* linear-gradient(115deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%), */
    /* linear-gradient(115deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%); */
		background-image: linear-gradient(
				115deg,
				#f8ce25,
				#800ff0,
				#0ea2dd,
				#10cf40
			),
			linear-gradient(115deg, #10cf40, #10cf40),
			linear-gradient(115deg, #f8ce25, #800ff0, #0ea2dd, #10cf40),
			linear-gradient(115deg, #f8ce25, #f8ce25);
	}

	50% {
		background-image: linear-gradient(
				115deg,
				#800ff0,
				#0ea2dd,
				#10cf40,
				#f8ce25
			),
			linear-gradient(115deg, #f8ce25, #f8ce25),
			linear-gradient(115deg, #800ff0, #0ea2dd, #10cf40, #f8ce25),
			linear-gradient(115deg, #800ff0, #800ff0);
	}

	75% {
		background-image: linear-gradient(
				115deg,
				#0ea2dd,
				#10cf40,
				#f8ce25,
				#800ff0
			),
			linear-gradient(115deg, #800ff0, #800ff0),
			linear-gradient(115deg, #0ea2dd, #10cf40, #f8ce25, #800ff0),
			linear-gradient(115deg, #0ea2dd, #0ea2dd);
	}
}

.level-item {
	/* padding: 30px; */
	/* padding: 10%; */
	/* padding: 10vh; */
  /* padding: 10vh 10vw; */
  /* padding: $rainbow-padding-h/2 $rainbow-padding-w/2; */
  padding: $rainbow-padding-h $rainbow-padding-w;
	position: relative;

	background-repeat: no-repeat;

	//background-size: calc(100% - 10px) 6px, /* top & bottom */
  //  6px calc(100% - 10px); /* right & left */

	background-size: calc(100% - 14px) 7px, /* top & bottom */
    7px calc(100% - 14px); /* right & left */

	//background-position: 5px 5px, /* top */ calc(100% - 5px) 5px,
	//	/* right */ 5px calc(100% - 5px), /* bottom */ 5px 5px; /* left */

	background-position: 7px 7px, /* top */ calc(100% - 7px) 7px,
		/* right */ 7px calc(100% - 7px), /* bottom */ 7px 7px; /* left */

  background-image: linear-gradient(115deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%),
    linear-gradient(115deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%),
    linear-gradient(115deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%),
    linear-gradient(115deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%);

	//background-image: linear-gradient(
	//		115deg,
	//		#10cf40,
	//		#f8ce25,
	//		#800ff0,
	//		#0ea2dd,
	//		#44ce7b
	//	),
	//	linear-gradient(115deg, #10cf40, #44ce7b),
	//	linear-gradient(115deg, #10cf40, #f8ce25, #800ff0, #0ea2dd, #44ce7b),
	//	linear-gradient(115deg, #10cf40, #44ce7b);

	//animation: level-item 0.4s linear infinite;
	//animation: level-item 0.7s linear infinite;
  /* border-radius: 10vw; */
	/* animation: level-item 4s linear infinite; */
  /* animation: slidebg 2s linear infinite; */
}
a {
  background-color:#191919;
  display:flex;
  text-decoration:none;
  color:#FFF;
}

.rainbow-content {
    color: white;
}

.buyButton {
  margin: 0 auto;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
}
.rainbow-button {
  // margin-top: calc(50vh - $button-height*3);

  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: calc($button-width + $button-rainbow-border);
  height: calc($button-height + $button-rainbow-border);
  background-image: linear-gradient(90deg, #00C0FF 0%, #FFCF00 49%, #FC4F4F 80%, #00C0FF 100%);
  /* background-image: linear-gradient(90deg, #ed1c24 0%, #02ab4d 49%, #0067ff 80%, #ed1c24 100%); */
  border-radius: $button-rainbow-border-radius;
  display:flex;
  align-items:center;
  justify-content:center;
  text-transform:uppercase;
  font-size: $button-font-size;
  font-weight: bold;
  animation: slidebg 2s linear infinite;
}
.rainbow-button:after {
  content: attr(alt);
  /* width:20vw; */
  width: $button-width;
  /* height:8vw; */
  height: $button-height;
  /* background-color:#191919; */
  background-color: black;
  display:flex;
  align-items:center;
  justify-content:center;
  border-radius: $button-rainbow-border-radius;
}
/* .rainbow-button:hover { */
/*   animation:slidebg 2s linear infinite; */
/* } */

@keyframes slidebg {
  to {
    background-position: $button-width + 2vw;
  }
}

/* #xshroom-logo { */
/*     display: flex; */
/*     justify-content: center; */
/*  */
/* } */

header, footer, main { 
    display: flex;
    justify-content: center;
    min-height: 100%;
    flex-direction: column;
    align-items: stretch;
    /* flex: 1 100%; */
    /* vertical-align: bottom; */
    /* vertical-align: flex-end; */

}
header {

  img {
    margin: auto;
  }
}
//main {
//  section {
//    margin-top: 100px;
//  }
//  flex-grow: 1;
//}
header, main, footer {
  flex-shrink: 0;
}

article {
  min-height: 100%;
  div {
    //margin-top: 9vh;
    margin-top: 3vh;
  }
  flex-direction: column;
  align-items: stretch;

}

#accountInfo {
  width: 50%;
  margin: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  small {
    display: block;
    background-color: lighten(#170305, 40%);
    //color: transparent;
    color: lighten(transparentize(#170305, 0.3), 40%);
    text-shadow: 0.5px 0.3px 0px transparentize(lighten(#170305, 47%), 0.4);
    -webkit-background-clip: text;
    -moz-background-clip: text; 
    background-clip: text;
    white-space: nowrap;
    overflow: hidden;

    text-overflow: ellipsis;
    background-image:linear-gradient(
    -75deg,
      transparent 0,
      transparent 5%,
      rgba(255, 255, 255, 0.5) 5%,
      rgba(255, 255, 255, 0.5) 10%,
      transparent 5%,
      transparent 15%,
    );
    background-size: 200% 100%;
    -webkit-text-fill-color:transparent;
    -webkit-background-clip:text;
    transition:1.2s;
    animation: shine 2s linear infinite;
    
  }
}

.actions {
    display: flex;
    a {
        width: 50%;
        min-height: 5vh;
        padding: 1.9vw;
        background-color: green;
        &:before {
            font-size: 30px;
            content: '\2705';
            padding-right: 20px;
            padding-left: 8px;
            padding-top: 6px;
        }
        & + a {
            color: red;
            background-color: #000;
            &:before {
                content: '\274C';
            }
        }
        &:active {
            background-color: $blue;
        }
    }
}

footer {
    /* background-color: #00000099; */
    background-color: transparent;
    display: block;
    /* position: fixed; */
    flex-shrink: 0;
    bottom: 0;
    h1#cost-header { 
        color: white;
        margin: 0; 
        padding: 0;
    }
    hr {
      width: 80%;
    }
    small.poweredByXUMM {
        font-size: 1.09vh;
        padding-bottom: 0.6vh;
        line-height: 1.0;
        vertical-align: bottom;
    }
    padding-top: 1vh;
    padding-bottom: 1vh;
    p,h1,small {
      display: block;
      padding-left: 8vw;
      padding-right: 8vw;
    }
    margin-bottom: 2vh;
    .xumm-logo img {
        max-width: 60px;
        vertical-align: bottom;
    }
}

#main-confirm {
  /* color: black; */
  /* background-color: rgba(2,2,2,0.1); */

  /* border-radius: $button-rainbow-border-radius; */
  /* display:flex; */
  /* align-items:center; */
  justify-content:center;
  text-align: center;
  text-transform:uppercase;
  /* font-size: $button-font-size; */
  /* font-weight:bold; */
  p { 
      padding: 0 10px;
  }
}

.critical {
    color: #ed1c24;
}
.success {
    color: #02ab4d;
}
.warning { 
    color: #0067ff;
}

.custom-rotate-transition {
  &-enter-active,
  &-leave-active {
    transition-timing-function: linear;
  }

  &-enter-from {
    transform: translate(0, 30px) rotate(12deg);
    opacity: 0;
  }

  &-leave-to {
    transform: translate(0, 30px) rotate(4deg);
    opacity: 0;
  }
}

hr { 
    border-top: .25pt solid #333;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  //margin-top: 60px;
}

#maxButtonContainer {
  display: inline-block;
  max-width: 100%;
  position: absolute;

  &>button {
    height: 46px;
    background-color: #fff;
    border: 0;
    border-radius: 0.25rem;
    bottom: 1px;
    position: absolute;
    top: 1px;
    width: 3.2rem;
    z-index: 1;
    &:focus {
      outline: none;
    }
    &::before,
    &::after {
      background-color: #111;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 0.15s;
    }
  }
}


.vue-number-input {
  max-width: 33%!important;

  &__input {
    max-width: 50%!important;
  }
}

</style>
