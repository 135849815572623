import { createApp } from 'vue'

// main.js
import App from './App.vue'
// import { XummSdk } from "xumm-sdk"
import { XummSdkJwt } from "xumm-sdk"

import 'gitart-vue-dialog/dist/style.css'
import { GDialog } from 'gitart-vue-dialog'
import { plugin as dialogPlugin } from 'gitart-vue-dialog'
import VueNumberInput from '@chenfengyuan/vue-number-input';

// const app = createApp(App).mount('#app')
// const app = createApp(App, {account})
// app.component('GDialog', GDialog)
// app.use(dialogPlugin)

const calculateMint = (tokenTrustLine) => {
  if (!tokenTrustLine) {
    return {inputBurned: 0, outputMinted: 0}
  } else if (tokenTrustLine.balance < 0.5) {
    return {inputBurned: tokenTrustLine.balance, outputMinted: 0}
  } else {
    const inputBalance = tokenTrustLine.balance
    const inputBurned = inputBalance - inputBalance % 0.5
    const outputMinted = Math.floor(inputBurned / 0.5)
    return {inputBurned, outputMinted}
  }
}
const xummPing = async () => {
  try {
    const xummsdk = new XummSdkJwt("dd4ddfb2-3332-4a5f-83f1-f162f274bf9d")
    const testnetIssuer = "r9rLLez84biAyZFtVWHY2UfXGKvxEnvX6G"
    const testnetCurrencyCode = "785368726F6F6D73546573740000000000000000"

    xummsdk.getOttData().then(c => {


      console.log("ott data", c)
      // lookup amount of xshroom or other token
      // after a successful xummsdk.ping()
      xummsdk.ping().then(c => {
        console.log("Pong", c)
        console.log(c.jwtData.sub)
        const ws = new WebSocket(c.jwtData.net)
        const account = c.jwtData.sub
        // 0. open
        ws.onopen = () => {
          ws.onmessage = m => {
            // 2. do something with message
            const response = JSON.parse(m.data)
            console.log("response is", response)
            const xShroomAccountLine = response.result.lines.filter(l => l.account === testnetIssuer && l.currency === testnetCurrencyCode)[0]
            // app.config.globalProperties.xShroomBalance = xShroomAccountLine.balance
            const calculated = calculateMint(xShroomAccountLine)
            // const app = calculated ? createApp(App, {
            //   account,
            //   inputBurned: calculated.inputBurned,
            //   outputMinted: calculated.outputMinted,
            // }) : createApp(App, {
            //   account,
            //   inputBurned: 0,
            //   outputMinted: 0
            // })
            const app = createApp(App, {
              account,
              inputBurned: calculated.inputBurned,
              outputMinted: calculated.outputMinted,
            })
            app.component('GDialog', GDialog)
            app.component(VueNumberInput.name, VueNumberInput);
            app.use(dialogPlugin)
            app.mount("#app")
          }
          ws.send(JSON.stringify({
            // 1. send on open
            command: "account_lines", account, limit: 1
          }))
        }
        // should i mount after ping?, yes
        // app.mount("#app", {
        //     propsData: {
        //       account: c.jwtData.sub,
        //     }
        // })
      })
    })

  } catch (e) {
    console.log({error: e.message, stack: e.stack})
  }

}

xummPing()
// app.config.globalProperties.token = token
// app.config.globalProperties.xummping = xummsdk.ping()
